// 1. Automatically loads a dark or light theme based on system preferences
// 2. Allows the user to manually override their system preference
// 3. Maintains the user's preferred theme on page reloads

// Get elements
const btn = document.getElementById("theme-toggle");
const iconDark = document.getElementById("theme-toggle-icon-dark");
const iconLight = document.getElementById("theme-toggle-icon-light");

function enableDarkMode() {
  document.body.classList.remove("light");
  document.body.classList.add("dark");

  iconDark.classList.remove("d-none");
  iconLight.classList.add("d-none");
};


function enableLightMode() {
  document.body.classList.remove("dark");
  document.body.classList.add("light");

  iconDark.classList.add("d-none");
  iconLight.classList.remove("d-none");
};


// Check for dark mode preference at the OS level
const prefersDarkScheme = window.matchMedia("(prefers-color-scheme: dark)").matches;

// Check for saved preferences
const hasTheme = localStorage.getItem("theme");
if (!hasTheme) {
  if (prefersDarkScheme) {
    localStorage.setItem("theme", "dark");
  } else {
    localStorage.setItem("theme", "light");
  }
}

// Toggle theme based on preferences
const currentTheme = localStorage.getItem("theme");
if (currentTheme === "dark") {
  enableDarkMode();
} else if (currentTheme === "light") {
  enableLightMode();
}

btn.addEventListener("click", function() {
  if (document.body.classList.contains("dark")) {
    enableLightMode();
    localStorage.setItem("theme", "light");
  } else {
    enableDarkMode();
    localStorage.setItem("theme", "dark");
  }
});
